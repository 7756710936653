.header {
  position: fixed;
}

.homeWrapper { 
  color: var(--colorBrandDark1);
  font-size: 1.125em;
}

.homeWrapper > section {
  box-sizing: border-box;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { top: 1rem;}
  to { top: 0;}
}

.mainContentWrapper {
  position: relative;
  animation: fadeIn 0.1s, slideUp 0.1s;
}

@media (min-width: 768px) {
  .homeWrapper {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .homeWrapper {
    font-size: 1.75rem;
  }
}
