.professionalSection {
  color: #767676;
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-top: 4rem;
  width: 100vw;
}

.professionalRecent,
.professionalPast {
  line-height: 1.5rem;
  max-width: 37rem;
}

.professionalRecent {
  margin-bottom: 1rem;
}

.professionalPast a {
  color: var(--colorBrandPrimary);
}

.professionalRecent__highlight {
  color: var(--colorBrandDark1);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}

.recentlySection { }

.recentlySection p {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto 2rem;
  max-width: 37rem;
}

.m2Wrapper { }

.m2RedesignScreens {
  box-sizing: border-box;
  margin: 0 auto 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  max-width: 37rem;
}

.m2RedesignScreens > div {
  display: inline-block;
  vertical-align: top;
  width: 53%;
}

.m2RedesignScreens img {
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 1rem rgba(0 0 0 / 0.1);
  position: relative;
  width: 100%;
}

.m2RedesignScreens__left { }

.m2RedesignScreens__right {
  margin-left: -6%;
  margin-top: 8rem;
}

.m2RedesignScreens__left > img {
  z-index: 2;
}

.m2RedesignScreens__right > img:first-child {
  z-index: 1;
  margin-bottom: 8rem;
}

.m2RedesignScreens__right > img:last-child {
  z-index: 3;
}

.mStyleGuideScreens {
  overflow: hidden;
  padding: 1rem 0;
  margin-top: -1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.mStyleGuideScreenWrapper:first-child {
  margin-top: 0;
}

.mStyleGuideScreenWrapper {
  margin-top: -8rem;
}

.mStyleGuideScreenWrapper:nth-child(2n) {
  text-align: right;
}

.mStyleGuideScreenWrapper > img {
  box-shadow: 0 0.25rem 1rem rgba(0 0 0 / 0.1);
  width: 80%;
}

.mStyleGuideScreenWrapper > img:nth-child(2n-1) {
  margin-left: -1rem;
}

.mStyleGuideScreenWrapper > img:nth-child(2n) {
  margin-right: -1rem;
}

@media (min-width: 37rem) {
  .professionalRecent,
  .professionalPast {
    margin: 0 auto;
  }

  .recentlySection p {
    padding-left: 0;
    padding-right: 0;
  }

  .mStyleGuideScreens {
    overflow: initial;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
