.headerWrapper {
  background: none;
  text-align: center;
  padding-top: 1rem;
  width: 100%;
  z-index: 9999;
}

.title { 
  text-indent: -9999px;
  height: 0;
}

.logo {
  width: 3rem;
  height: 3rem;
}
