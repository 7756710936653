.greetingSection {
  padding: 40vh 1rem 0;
  max-width: 37.5rem;
}

@media (min-width: 37.5rem) {
  .greetingSection {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}
