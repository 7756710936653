.consultingSection {
  padding: 0 1rem;
  margin-top: 1rem;
  max-width: 37rem;
}

.consultingSection__inner {
  border-radius: 0.5rem;
  background: #F7F4F8;
  color: var(--colorBrandDark1);
  padding: 1rem;
  text-align: center;
}

.consultingSection p {
  color: #75647A;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.consultingSection p:last-child {
  margin: 0;
}

.consultingSection .consultingHighlight {
  color: var(--colorBrandDark1);
  font-weight: bold;
  font-style: normal;
  margin-bottom: 0.5rem;
}

.consultingSectionCta {
  font-size: 1rem;
}

@media (min-width: 37rem) {
  .consultingSection {
    margin-left: auto;
    margin-right: auto;
  }
}
